

import {defaultLocale, LOCALE_STORAGE_KEY} from '@/app/config'
import {localize} from '@plugins/vee-validate'
import {locales} from '@plugins/vue-i18n/register'
import {camelCase, isString} from 'lodash'
import {mapActions, mapGetters, mapMutations} from 'vuex'
import snakeCase from "lodash/snakeCase";

const defaultAxiosCountDown = 310
const selectedSubItem= null;
export default
{
  methods: {
    IsAppLocale(locale) {
      return this.AppLocales.find(l => l.code === locale)
    },
    SetAppLocale(locale) {
      this.AppLocale = locale
      this.$nextTick(() => window.location.reload())
    },
    IniAppLocale() {
      this.AppLocale = localStorage.getItem(LOCALE_STORAGE_KEY) || defaultLocale
    },
    getPageTitle() {
      const t = (...a) => this.$t(...a)
      const tc = (...a) => this.$tc(...a)
      const e = (...a) => this.$te(...a)
      const _ = this.$_
      let str = ''

      let {name, pageName} = this.$options || {}
      let routePath = this.$route.path.split('/').pop();
      let routeName = this.$route.name.split('/').pop();
      // console.log(this.$t(pageName));
      if(routePath && e(routePath) && isString((str = t(routePath)))) {
        return str
      }

      routePath = camelCase(routePath)
      if(routePath && e(routePath) && isString((str = t(routePath)))) {
        return str
      }

      if(routeName && e(routeName) && isString((str = t(routeName)))) {
        return str
      }

      routeName = camelCase(routeName)
      if(routeName && e(routeName) && isString((str = t(routeName)))) {
        return str
      }

      let $routes
      let $routes2

      $routes = `$routes.${routeName}`
      $routes2 = `$routes.${routeName}`
      if(routeName && e($routes) && isString((str = t($routes)))) {
        return str
      }
      if(routeName && e($routes2) && isString((str = t($routes2)))) {
        return str
      }
      let pluralize = _.pluralize(_.upperFirst(routeName.split('Index').join('').split('index').join('')))
      pluralize = pluralize.split('Client').join('').split('client').join('');
      // let pluralize2 = _.pluralize(_.upperFirst(routeName.split('Client').join('').split('client').join('')))
      $routes = `choice.${pluralize}`
      // console.log(tc($routes, 2));

      if($routes && e($routes) && isString((str = tc($routes, 2)))) {
        return str
      }

      if(e(`${name}.name`) && isString((str = t(`${name}.name`)))) {
        return str
      }

      if(e(name) && isString((str = t(name)))) {
        return str
      }

      name = camelCase(name)
      if(e(`${name}.name`) && isString((str = t(`${name}.name`)))) {
        return str
      }

      if(e(name) && isString((str = t(name)))) {
        return str
      }

      if(e(pageName) && isString((str = t(pageName)))) {
        return str
      }

      return ''
    },
    current_user_data() {
      return JSON.parse(localStorage.getItem('current_user'));
    },
    getPageName() {
      let returnOBJ = {};
      let routeName = this.$route.name.split('/').pop()
      returnOBJ.name = snakeCase(routeName.split('Index').join('').split('index').join(''))
      returnOBJ.nameStudly = this.$_.upperFirst(returnOBJ.name)
      // console.log(returnOBJ);
      return returnOBJ;
    },
    parseAttribute(string, ...args) {
      if(!string) return string

      const _ = this.$_, t = (...a) => this.$t(...a), te = (...a) => this.$te(...a)

      let key = (this.$helpers.isOnlyObject(string) ? (string.text || '') : string).toString()

      if(te(key) && isString(t(key)))
        return t(key, ...args)

      if(te(`attributes.${key}`) && isString(t(`attributes.${key}`)))
        return t(`attributes.${key}`, ...args)

      return string
    },
    HasEditPermission() {
      // console.log(this.getPageName().name + '_edit');
      return this.HasPermission(this.getPageName().name + '_edit');
    },
    HasDeletePermission() {
      return this.HasPermission(this.getPageName().name+'_delete');
    },
    HasPermission(string) {
      if(!string) return false

      let allowedperms = JSON.parse(localStorage.getItem('perms'));

      if(allowedperms){
        if(allowedperms[string]){
          return true;
        }
      }
      return false
    },
    getAxiosName() {
      return this.$options.name
    },
    getAxiosItems() {
      return this.getIniAxios(this.getAxiosName())
    },
    setIniAxios(name, v) {
      this.$root.iniAxios[name] = v
    },
    getIniAxios(name) {
      return null
    },
    increaseAxios(countdown = defaultAxiosCountDown) {
      countdown = parseInt(countdown) || defaultAxiosCountDown
      this.$root.AxiosCountdown += countdown
      // console.log(this.$root.AxiosCountdown);
      return this.getAxiosCountdown()
    },
    setAxiosCountdown(countdown = defaultAxiosCountDown) {
      this.$root.AxiosCountdown = countdown
    },
    getAxiosCountdown() {
      return this.$root.AxiosCountdown
    },
    queueAxios(func, countdown = defaultAxiosCountDown) {
      // console.log(countdown);
      setTimeout(() => setTimeout(() => this.$nextTick(func), this.increaseAxios(countdown)), 60)
    },
  },
  computed: {
    APP_DEBUG: () => process.env.NODE_ENV === 'development',
    DefaultAppLocale: () => defaultLocale,
    AppRtl: {
      set(v) {
        this.$root.$vuetify.rtl = v
      },
      get() {
        const {rtl} = this.$root.$vuetify || {}
        return rtl !== undefined ? rtl : true
      }
    },
    Permissions: {
      set(v) {

      },
      get() {
        return JSON.parse(localStorage.getItem('perms'));
      }
    },
    attachment_types() {
      return [
        {
          text: this.$t('attributes.attachment_type1'),
          value: 1
        },
        {
          text: this.$t('attributes.attachment_type2'),
          value: 2
        }
      ]
    },
    LubricantsFilesTypes() {
      return [
        {
          text: this.$t('attributes.LubricantsType1'),
          value: 1
        },
        {
          text: this.$t('attributes.LubricantsType2'),
          value: 2
        },
        {
          text: this.$t('attributes.LubricantsType3'),
          value: 3
        },
        {
          text: this.$t('attributes.LubricantsType4'),
          value: 4
        }
      ]
    },
    user_types() {
      return [
        {
          text: this.$t('attributes.user_type1'),
          value: 1
        },
        {
          text: this.$t('attributes.user_type2'),
          value: 2
        },
        {
          text: this.$t('attributes.user_type3'),
          value: 3
        }
      ]
    },
    identity_types() {
      return [
        {
          text: this.$t('attributes.identity_type1'),
          value: 1
        },
        {
          text: this.$t('attributes.identity_type2'),
          value: 2
        }
      ]
    },
    product_types() {
      return [
        {
          text: this.$t('attributes.product_type1'),
          value: 1
        },
        {
          text: this.$t('attributes.product_type2'),
          value: 2
        }
      ]
    },
    action_types() {
      return [
        {
          text: this.$t('attributes.action_type1'),
          value: 1
        },
        {
          text: this.$t('attributes.action_type2'),
          value: 2
        }
      ]
    },
    product_categories() {
      return [
        {
          text: this.$t('attributes.product_category0'),
          value: 0
        },
        {
          text: this.$t('attributes.product_category1'),
          value: 1
        }
      ]
    },
    lubricant_category_types() {
      return [
        {
          text: this.parseAttribute('category_type1'),
          value: 1
        },
        {
          text: this.parseAttribute('category_type2'),
          value: 2
        }
      ]
    },
    color_types() {
      return [
        {
          text: this.$t('attributes.color_type0'),
          value: 0
        },
        {
          text: this.$t('attributes.color_type1'),
          value: 1
        },
        {
          text: this.$t('attributes.color_type2'),
          value: 2
        },
      ]
    },
    order_statuses() {
      return [
        {
          text: this.$t('attributes.order_status0'),
          value: 0
        },
        {
          text: this.$t('attributes.order_status1'),
          value: 1
        },
        {
          text: this.$t('attributes.order_status2'),
          value: 2
        },
        {
          text: this.$t('attributes.order_status3'),
          value: 3
        },
        {
          text: this.$t('attributes.order_status4'),
          value: 4
        },
        {
          text: this.$t('attributes.order_status5'),
          value: 5
        },
        {
          text: this.$t('attributes.order_status6'),
          value: 6
        },
        {
          text: this.$t('attributes.order_status7'),
          value: 7
        },
        {
          text: this.$t('attributes.order_status8'),
          value: 8
        },
        {
          text: this.$t('attributes.order_status9'),
          value: 9
        },
        {
          text: this.$t('attributes.order_status10'),
          value: 10
        },
        {
          text: this.$t('attributes.order_status11'),
          value: 11
        },
        {
          text: this.$t('attributes.order_status12'),
          value: 12
        },
        {
          text: this.$t('attributes.order_status13'),
          value: 13
        },
      ]
    },
    identity_statuses() {
      return [
        {
          text: this.$t('attributes.identity_status0'),
          value: 0
        },
        {
          text: this.$t('attributes.identity_status1'),
          value: 1
        },
        {
          text: this.$t('attributes.identity_status2'),
          value: 2
        },
        {
          text: this.$t('attributes.identity_status3'),
          value: 3
        },
        {
          text: this.$t('attributes.identity_status4'),
          value: 4
        },
        {
          text: this.$t('attributes.identity_status5'),
          value: 5
        },
      ]
    },
    GlobalStatus() {
      return [
        {
          text: this.parseAttribute('enabled'),
          value: true
        },
        {
          text: this.parseAttribute('disabled'),
          value: false
        },
      ]
    },
    identity_statuses2() {
      return [
        {
          text: this.$t('attributes.identity_status0'),
          value: 0
        },
        {
          text: this.$t('attributes.identity_status1'),
          value: 1
        },
        {
          text: this.$t('attributes.identity_status5'),
          value: 5
        },
      ]
    },
    product_statuses() {
      return [
        {
          text: this.$t('attributes.product_status1'),
          value: 1
        },
        {
          text: this.$t('attributes.product_status2'),
          value: 2
        },
        {
          text: this.$t('attributes.product_status3'),
          value: 3
        },
        {
          text: this.$t('attributes.product_status4'),
          value: 4
        },
      ]
    },
    product_statuses2() {
      return [
        {
          text: this.$t('attributes.product_status1'),
          value: 1
        },
        {
          text: this.$t('attributes.product_status2'),
          value: 2
        },
        {
          text: this.$t('attributes.product_status3'),
          value: 3
        },
        {
          text: this.$t('attributes.product_status4'),
          value: 4
        },
      ]
    },
    stamp_statuses() {
      return [
        {
          text: this.parseAttribute('disabled'),
          value: false
        },
        {
          text: this.parseAttribute('enabled'),
          value: true
        },
      ]
    },
    stamp_statuses2() {
      return [
        {
          text: this.parseAttribute('disabled'),
          value: 0
        },
        {
          text: this.parseAttribute('enabled'),
          value: 1
        },
      ]
    },
    stamp_colors() {
      return [
        {
          text: this.parseAttribute('stamp_color0'),
          value: 0
        },
        {
          text: this.parseAttribute('stamp_color1'),
          value: 1
        },
        {
          text: this.parseAttribute('stamp_color2'),
          value: 2
        },
        {
          text: this.parseAttribute('stamp_color3'),
          value: 3
        },
        {
          text: this.parseAttribute('stamp_color4'),
          value: 4
        },
        {
          text: this.parseAttribute('stamp_color5'),
          value: 5
        },
        {
          text: this.parseAttribute('stamp_color6'),
          value: 6
        },
        {
          text: this.parseAttribute('stamp_color7'),
          value: 7
        },
      ]
    },
    stamp_locations() {
      return [
        {
          text: this.parseAttribute('stamp_location0'),
          value: 0
        },
        {
          text: this.parseAttribute('stamp_location1'),
          value: 1
        },
        {
          text: this.parseAttribute('stamp_location2'),
          value: 2
        },
        {
          text: this.parseAttribute('stamp_location3'),
          value: 3
        },
      ]
    },
    DealerTypes() {
      return [
        {
          text: this.parseAttribute('dealer_type1'),
          value: 1
        },
        {
          text: this.parseAttribute('dealer_type2'),
          value: 2
        },
      ]
    },
    UnitTypes() {
      return [
        {
          text: this.parseAttribute('unit1'),
          value: 1
        },
        {
          text: this.parseAttribute('unit2'),
          value: 2
        },
      ]
    },
    Regions() {
      return [
        {
          text: this.parseAttribute('region1'),
          value: 1
        },
        {
          text: this.parseAttribute('region2'),
          value: 2
        },
        {
          text: this.parseAttribute('region3'),
          value: 3
        },
        {
          text: this.parseAttribute('region4'),
          value: 4
        },
        {
          text: this.parseAttribute('region5'),
          value: 5
        },
      ]
    },
    AppDirection: {
      set(v) {
        this.AppRtl = v.toLowerCase() === 'rtl'
      },
      get() {
        return this.AppRtl ? 'rtl' : 'ltr'
      }
    },
    AppAlign: {
      set(v) {
        this.AppRtl = v.toLowerCase() === 'right'
      },
      get() {
        return this.AppRtl ? 'right' : 'left'
      }
    },
    AppLocales() {
      return locales.map(code => ({title: this.$t(code), code}))
    },
    AppLocale: {
      set(locale = defaultLocale) {
        locale = ('' + locale).toString().toLocaleLowerCase()

        !this.IsAppLocale(locale) && (locale = defaultLocale)

        localStorage.setItem(LOCALE_STORAGE_KEY, locale)
        document.documentElement.setAttribute('lang', locale)
        document.documentElement.setAttribute('dir', locale === 'ar' ? 'rtl' : 'ltr')

        // Vuetify
        this.$root.$i18n.locale = locale
        this.$root.$vuetify.lang.current = locale
        this.$root.$vuetify.rtl = locale === 'ar'

        // axios
        this.$axios.defaults.headers.common['Locale'] = locale

        // Vuex
        const validations = require(`@locales/${locale}/validations.js`).default
        localize(locale, validations.default || validations)

        // moment js
        this.$moment.locale(locale)
      },
      get() {
        return this.$root.$vuetify.lang.current
      }
    },
    themeDark: {
      get() {
        return Boolean(mapGetters('auth', ['themeDark']).themeDark.call(this))
      },
      set(value) {
        this.$vuetify.theme.dark = value
        mapActions('auth', ['setThemeDark']).setThemeDark.call(this, value)
      }
    },
    themeLight: {
      get() {
        // console.log(this.themeDark)
        return !Boolean(this.themeDark)
      },
      set(value) {
        value = !value
        this.$vuetify.theme.dark = value
        mapActions('auth', ['setThemeDark']).setThemeDark.call(this, value)
      }
    },
    themeColor() {
      return this.themeDark ? 'dark' : 'light'
    },
    AppIsMobile() {
      return this.$root.$vuetify.breakpoint.smAndDown || false
    },
    authUser: {
      set(user) {
        mapMutations('auth', ['setUser']).setUser.call(this, user)
      },
      get() {
        return mapGetters('auth', ['getUser']).getUser.call(this)
      }
    },
    apiService() {
      return this.$api.methods
    },
  }
}
