/*
  SarhSoft Cross-PlatForm Engine Project
  Languages: PHP7.4, JavaScript, Node, Java.
  BackEnd: Laravel8.
  FrontEnd: Vue2.
  Project Type: (Laravel,Vue Mixin) Java Cross Frame.
  Written By: M.Farhat,
  Email: m.farhat@sarhsoft.com.
  Copyright © 2022 SarhSoft All rights reserved.
*/
export default {
  nationality_id:  'Nationality',
  identity:        'Identication',
  identity_expiry_date:        'Identication expiry date',
  identity_type:   'Identity Type',
  identity_status: 'Identity Status',
  is_verified:     'Verified',
  photo_uploaded:  'ID Photo uploaded',
  user_id:  'User',
  user_name:  'User Name',
  user_mobile:  'User Mobile',
  user_email:  'User Email',
};
