export default {
      name: 'name',
    namear: 'namear',
    role: 'Permission Role',
    role_id: 'Permission Role',
    login_id: 'login_id',
    password: 'password',
    client_id: 'Client',
    remember_token: 'remember_token',
    email_verified_at: 'email_verified_at',
    email: 'email',
    language: 'language',
    status: 'status',
    mobile: 'mobile',

};
