/*
  SarhSoft Cross-PlatForm Engine Project
  Languages: PHP7.4, JavaScript, Node, Java.
  BackEnd: Laravel8.
  FrontEnd: Vue2.
  Project Type: (Laravel,Vue Mixin) Java Cross Frame.
  Written By: M.Farhat,
  Email: m.farhat@sarhsoft.com.
  Copyright © 2022 SarhSoft All rights reserved.
*/
import routes from '@routes/config';

export default [{
    path: routes.mainCity.index,
    name: routes.mainCity.index,
    component: () => import(/* webpackChunkName: "MainCity"*/  '@components/MainCity/Index')
}]
