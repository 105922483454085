/*
  SarhSoft Cross-PlatForm Engine Project
  Languages: PHP7.4, JavaScript, Node, Java.
  BackEnd: Laravel8.
  FrontEnd: Vue2.
  Project Type: (Laravel,Vue Mixin) Java Cross Frame.
  Written By: M.Farhat,
  Email: m.farhat@sarhsoft.com.
  Copyright © 2022 SarhSoft All rights reserved.
*/
export default {
    dealer_type: 'Dealer Type',
    contact_number: 'Contact Number',
    region: 'Region',
    latitude: 'Latitude',
    longitude: 'Longitude',
    note: 'Note',
    status: 'Status',
    dealer_type0: 'Un Defined',
    dealer_type1: 'Lubricants',
    dealer_type2: 'Spare Parts',
    unit: 'unit',
    unit0: 'Un Defined',
    unit1: 'Box',
    unit2: 'Piece',
    icon0: 'غير محدد',
    icon1: 'مكتب',
    icon2: 'بنشر',
    region0: 'Un Defined',
    region1: 'Central Area',
    region2: 'Eastern Area',
    region3: 'Western Area',
    region4: 'Northern Area',
    region5: 'Southern Area',
    main_city_id: 'City',
};
