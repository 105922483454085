/*
  SarhSoft Cross-PlatForm Engine Project
  Languages: PHP7.4, JavaScript, Node, Java.
  BackEnd: Laravel8.
  FrontEnd: Vue2.
  Project Type: (Laravel,Vue Mixin) Java Cross Frame.
  Written By: M.Farhat,
  Email: m.farhat@sarhsoft.com.
  Copyright © 2022 SarhSoft All rights reserved.
*/
export default {
    nationality_id:  'الجنسية',
    identity:        'الهوية',
    identity_expiry_date:        'انتهاء الهوية',
    identity_type:   'نوع الهوية',
    identity_status: 'حالة الهوية',
    identity_status0: 'بانتظار رفع صورة الهوية',
    identity_status1: 'قيد المراجعة',
    identity_status2: 'صورة الهوية غير مطابقة للبيانات المدخلة',
    identity_status3: 'مرفوضة',
    identity_status4: 'مفعلة',
    identity_status5: 'صورة الهوية المرفوعة فارغة',
    is_verified:     'مفعلة',
    photo_uploaded:  'رفع صورة الهوية',
    user_id:  'المستخدم',
    user_name:  'اسم المستخدم',
    user_mobile:  'جوال المستخدم',
    user_email:  'ايميل المستخدم',
};
