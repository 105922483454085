/*
  SarhSoft Cross-PlatForm Engine Project
  Languages: PHP7.4, JavaScript, Node, Java.
  BackEnd: Laravel8.
  FrontEnd: Vue2.
  Project Type: (Laravel,Vue Mixin) Java Cross Frame.
  Written By: M.Farhat,
  Email: m.farhat@sarhsoft.com.
  Copyright © 2022 SarhSoft All rights reserved.
*/
export default {
    product_sku: 'SKU',
    manufacturer_id: 'الشركة المصنعة',
    product_model_id: 'نوع المركبة',
    product_year_id: 'سنة التصنيع',
    product_out_color_id: 'اللون الخارجي',
    product_in_color_id: 'اللون الداخلي',
    import_type_id: 'نوع الإستيراد',
    fuel_type_id: 'نوع الوقود',
    km: 'كيلو متر',
    chassie: 'رقم الهيكل',
    product_type: 'نوع المنتج',
    price: 'السعر',
    contain: 'يحتوي على',
    contain1: 'شد',
    down_payment: 'الدفعة المقدمة',
    brokerage_with_vat: 'رسوم الوساطة مع الضريبة',
    brokerage_with_vat_only: 'الرسوم+الضريبة',
    brokerage_services: 'رسوم الوساطة',
    brokerage_services_only: 'الرسوم',
    body_check: 'فحص البودي',
    product_notes: 'مواصفات اضافية',
    product_type0: 'لا يوجد',
    product_type1: 'سيارة',
    product_type2: 'قطعة غيار',
    product_status: 'حالة المنتج',
    product_status1: 'متاحة',
    product_status2: 'محجوز بانتظار التأكيد',
    product_status3: 'محجوز',
    product_status4: 'مباع',
    attachments: 'مرفقات المنتج',
    attachment: 'المرفق',
    attachment_type: 'نوع المرفق',
    attachment_type1: 'صورة',
    attachment_type2: 'فيديو',
    product_category: 'تصنيف المنتج',
    product_category0: 'منتج عام',
    product_category1: 'منتج خاص',
    color_type: 'نوع اللون',
    color_type0: 'عام',
    color_type1: 'خارجي',
    color_type2: 'داخلي',
};
