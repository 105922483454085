

<template>
  <div>
    <v-menu
      v-model="menuShow"
        :close-on-click="closeOnClick"
        :close-on-content-click="closeOnContentClick"
        :left="!AppRtl"
        :right="AppRtl"
        bottom
        offset-y
        transition="scale-transition">
      <template v-slot:activator="{ attrs, on }">
        <v-btn
            v-bind="attrs"
            v-on="on"
            icon>
          <v-badge
              :color="badgeColor"
              left
              overlap
              :loading="fetching"
          >
            <template
                v-if="unreadCount"
                v-slot:badge
            >
              <span>{{ unreadCount }}</span>
            </template>
            <v-icon style="color: rgb(42 54 134) !important;">mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <template v-if="fetching">
        <app-skeleton
            :loading="fetching"/>
      </template>
      <template v-else>
        <v-list
          :min-width="AppIsMobile?APPminWidth:minWidth" :width="AppIsMobile?APPwidth:width" :max-height="250"
          style="background: #f9f9f9;overflow-y:auto;" nav two-line>
          <template v-if="hasNotifications">
            <template v-for="(notification) in notificationsComputed">
              <v-hover v-slot="{hover}">
                <v-list-item
                    :class="{ 'active-item darken-3 whiteOnHover white--text elevation-12': hover, 'secondary whiteOnHover white--text':!hover&&notification.unread}"
                    link
                    style="min-height: 40px;"
                    @click="clickNotification(notification)"
                >
                  <v-list-item-avatar class="pa-0 ma-0" style="color: inherit !important;">
                    <v-icon style="color: inherit !important;">{{ notification.icon }}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content style="color: inherit" dense class="pa-0 ma-0">
<!--                    <v-list-item-title style="color: inherit" dense class="pa-0 ma-0">{{ notification.title }}</v-list-item-title>-->
                    <v-list-item-subtitle style="color: inherit" dense class="text-center">{{ notification.text }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <!--              <v-list-item-icon v-if="notification.icon">-->
                  <!--                <v-icon small>{{ notification.icon }}</v-icon>-->
                  <!--              </v-list-item-icon>-->
                  <!--              <v-list-item-content>{{ notification.text }}</v-list-item-content>-->
                  <!--              <v-list-item-content>item-content</v-list-item-content>-->
                  <!--              <v-list-item-subtitle class="text-start">subtitle</v-list-item-subtitle>-->
                  <!--              <app-notification-item>-->
                  <!--                {{ notification.text }}-->
                  <!--                <v-list-item-title class="text-start"></v-list-item-title>-->
                  <!--              </app-notification-item>-->
                </v-list-item>
              </v-hover>
            </template>
          </template>
          <template v-else>
            <v-subheader>{{ $t('none') }}</v-subheader>
          </template>
          <v-hover v-slot="{hover}">
            <v-list-item
              :class="{ 'active-item darken-3 whiteOnHover white--text elevation-12': hover}"
              link
              @click="clickAllNotification()"
              style="min-height: 40px;">
              <v-list-item-avatar class="pa-0 ma-0" style="color: inherit !important;">
                <v-icon style="color: inherit !important;">apps</v-icon>
              </v-list-item-avatar>
              <v-list-item-content style="color: inherit" dense class="pa-0 ma-0">
                <v-list-item-subtitle style="color: inherit" dense class="text-center">{{ $t('all_notifications') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-hover>
        </v-list>
      </template>
    </v-menu>
    <v-dialog v-model="dialog.show" max-width="1024">
      <v-card flat loader-height="30" tile>
        <v-toolbar class="justify-content-center" color="secondary" max-height="30" height="30">
          <v-toolbar-title v-text="dialog.title"/>
        </v-toolbar>
        <v-card-text class="pt-2">
          <div class="pt-2">
            <template v-for="(notification) in allnotifications">
                <v-hover v-slot="{hover}">
                  <app-row
                    :class="{ 'primary whiteOnHover elevation-12 pa-0 ma-0': hover,'pa-0 ma-0':!hover}"
                    link
                    style="min-height: 40px;"
                    @click="clickNotification(notification)">
<!--                    <v-col class="pa-0 ma-0" cols="3" dense>-->
<!--                      <v-avatar class="pa-0 ma-0" style="color: inherit !important;">-->
<!--                        <v-icon style="color: inherit !important;">{{ notification.icon }}</v-icon>-->
<!--                      </v-avatar>-->
<!--                    </v-col>-->
                      <v-col class="pa-0 ma-0" cols="12" dense>
                      <v-card-text style="color: inherit" dense class="pa-0 pt-2 ma-0 text-center">
                        {{ notification.text }}
                      </v-card-text>
                    </v-col>
                  </app-row>
                </v-hover>
              </template>
          </div>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer></v-spacer>
          <app-btn color="error" min-width="120" tooltip="close_window" @click="closeDialog">
            {{ $t('close') }}
          </app-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style>
.whiteOnHover{
  color: white !important;
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
  color: white !important;
}
</style>
<script>
// Components
import {VHover, VListItem} from 'vuetify/lib';
export default {
  name: 'NotificationsMenu',
  components: {
    AppNotificationItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({hover}) => {
              return h(VListItem, {
                attrs: this.$attrs,
                class: {
                  'black--text': !hover,
                  'secondary elevation-12': hover
                },
                props: {
                  activeClass: '',
                  dark: hover,
                  link: true,
                  ...this.$attrs
                }
              }, this.$slots.default);
            }
          }
        });
      }
    }
  },
  data() {
    return {
      menuShow: !1,
      // CarShow: !1,
      // CarIdShow: 0,
      closeOnClick: !0,
      closeOnContentClick: !1,
      APPminWidth: '200px',
      APPwidth: '300',
      minWidth: '500px',
      width: '600',
      unreadCount: undefined,
      fetched: !1,
      fetching: !1,
      // showCar: !1,
      fetchedUnreadCount: !1,
      fetchingUnreadCount: !1,
      notifications: [],
      allnotifications: [],
      dialog: {
        show: !1,
        title: '',
        text: ''
      }
    };
  },
  computed: {
    badgeColor() {
      return this.unreadCount > 0 ? 'error' : 'transition';
    },
    notificationsComputed: {
      get() {
        return this.notifications;
      },
      set(v) {
        this.notifications = v;
      }
    },
    hasNotifications() {
      return this.notifications.length > 0;
    }
  },
  methods: {
    FetchNotifications(){
      let getprocess = Promise.resolve();
      if(!this.menuShow) {
        this.fetching = !0;
        this.unreadCount = 0;
        this.notifications = [];
        getprocess = this.$axios.get(`Panel/GetNotifications`, {params: {}})
          .then(resdata => {
            this.unreadCount = resdata.data.unreadCount;
            this.notifications = resdata.data.data;
            this.fetching = !1;
          }).catch(errordata => {
            console.log(errordata.response);
            this.fetching = !1;
          });
      }
      getprocess.finally(()=>{
        this.fetching = !1;
        setTimeout(()=>{
          this.FetchNotifications();
        },30000)
      });
    },
    clickAllNotification() {
      this.allnotifications = [];
      this.$axios.get(`Panel/GetAllNotifications`, {params: {}})
        .then(resdata => {
          this.allnotifications = resdata.data.data;
        }).catch(errordata => {
        console.log(errordata.response);
      });
      this.showDialog(this.$t('all_notifications'));
    },
    clickNotification(notification) {
      this.notifications.map(e => {
        if(e.id === notification.id) {
          e.unread = false;
          e.read = true;
        }
        return e;
      });
      this.unreadCount > 0 && --this.unreadCount;
      this.$axios.get(`Panel/UpdateNotification`, {params: {
          notification_id:notification.id
        }})
        .then(resdata => {
        })
        .catch(errordata => {
          console.log(errordata.response);
        });
      if(notification.type==='attributes_global.new_id_validation_request'){
        if(this.$router.currentRoute.name==='user-identity-index'){this.$router.go();}
        else{
          this.dialog.show = !1;
          this.$router.push('user-identity-index');
        }
      }
    },
    showDialog(title = '') {
      this.dialog.show = !0;
      this.dialog.title = title;
    },
    closeDialog() {
      this.dialog.show = !1;
      this.dialog.text = '';
      this.dialog.title = '';
    },
    fetchNotifications() {
      // if(this.fetching || this.fetched) return;
      if(this.fetching) return;
      this.fetching = !0;

      return this
      .$api.methods.notification.index({limit: 15})
      .then(({data}) => {
        let notifications = [];
        if(data && data.success && data.data) {
          // this.fetched = !0;
          data = data.data;
          notifications = data.notifications;
          this.unreadCount = data.unreadCount;
        }
        this.notifications = notifications;
        // setTimeout(this.fetchUnreadCount, 500);
      })
      .finally(() => this.fetching = !1);
    },
    fetchUnreadCount() {
      if(this.fetchingUnreadCount || this.fetchedUnreadCount) return;
      this.fetchingUnreadCount = !0;

      return this
      .$api.methods.notification.indexUnreadCount()
      .then(({data}) => {
        let r;
        if(data && (r = data.data)) {
          this.fetchedUnreadCount = !0;
          this.unreadCount = parseInt(r.count) || null;
        }
      })
      .finally(() => this.fetchingUnreadCount = !1);
    },
    ini() {
      if(this.fetched && this.fetchedUnreadCount)
        return;
      this.fetchNotifications();
      setInterval(this.fetchNotifications, (10 * 1000));
    }
  },
  watch: {
    // '$root.isLogin': {
    //   handler(newVal) {
    //     newVal && this.ini();
    //   },
    //   deep: !0,
    //   immediate: !0
    // }
  },
  mounted() {
    this.FetchNotifications();
  }
};
</script>
<style
    lang="scss"
    scoped
>
.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled).active-notification {

  &:hover {
    color: var(--v-secondary-base) !important;
  }
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled).active-notification {

  &:hover {
    color: var(--v-primary-base) !important;
  }
}
</style>
