/*
  SarhSoft Cross-PlatForm Engine Project
  Languages: PHP7.4, JavaScript, Node, Java.
  BackEnd: Laravel8.
  FrontEnd: Vue2.
  Project Type: (Laravel,Vue Mixin) Java Cross Frame.l
  Written By: M.Farhat,
  Email: m.farhat@sarhsoft.com.
  Copyright © 2022 SarhSoft All rights reserved.
*/
export default {
      lubricant_id: 'منتج الزيت',
      lubricant_property_id: 'خاصية الزيت',
      lubricant_property_ar: 'خصائص الزيت عربي',
      lubricant_property_en: 'خصائص الزيت انجليزي',

};
