<template>
  <v-app-bar
    :height="!this.AppIsMobile?100:50"
    v-bind="$attrs"
    v-on="$listeners"
    :clipped-left="!AppRtl"
    :clipped-right="AppRtl"
    class="text-light"
    style="color: #ffffffb3;background: -webkit-linear-gradient(top, rgb(255 255 255) 0%, rgb(165 169 197) 100%) !important" app color="primary">
    <v-app-bar-nav-icon
      id="drawerBTN"
      style="color: rgb(42, 54, 134);"
      v-if="showDrawerIcon"
      @click.stop="drawer = !drawer"
    ></v-app-bar-nav-icon>&nbsp;
    <v-avatar style="cursor: pointer;" @click="clickme" rounded :width="!this.AppIsMobile?300:150" :height="!this.AppIsMobile?90:45">
      <v-img contain :src="AppLogo2"></v-img>
    </v-avatar>
    <app-guest-buttons-bar/>
    <v-spacer></v-spacer>
    <v-btn
        v-show="user"
        style="color: rgb(42, 54, 134);"
        :to="route($config.routes.user.profile)"
        icon>
      <v-icon style="color: rgb(42, 54, 134);">mdi-account</v-icon>
    </v-btn>
    <div class="mx-1"></div>
        <template v-if="user">
          <app-notifications-menu/>
          <div class="mx-1"></div>
        </template>
    <app-language-menu/>
  </v-app-bar>
</template>
<script>
export default {
  name: 'Bar',
  props: {
    value: {
      type: Boolean
    }
  },
  methods:{
    clickme(){
      return this.$router.push('home');
    }
  },
  computed: {
    logoColor() {
      return this.themeDark ? 'gray' : 'white';
    },
    showDrawerIcon() {
      return this.isLogin;
    },
    drawer: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', Boolean(v));
      }
    },
    user() {
      return this.$root.authUser;
    }
  }
};
</script>
