/*
  SarhSoft Cross-PlatForm Engine Project
  Languages: PHP7.4, JavaScript, Node, Java.
  BackEnd: Laravel8.
  FrontEnd: Vue2.
  Project Type: (Laravel,Vue Mixin) Java Cross Frame.
  Written By: M.Farhat,
  Email: m.farhat@sarhsoft.com.
  Copyright © 2022 SarhSoft All rights reserved.
*/
import routes from '@routes/config';

export default [
  {
    path: routes.order.index,
    name: routes.order.index,
    component: () => import(/* webpackChunkName: "Order"*/  '@components/Order/Index')
  },
  {
    path: routes.order.orderApprovePayment,
    name: routes.order.orderApprovePayment,
    component: () => import(/* webpackChunkName: "OrderApprovePayment"*/  '@components/Order/ApprovePayment')
  },
  {
    path: routes.order.orderStartShipment,
    name: routes.order.orderStartShipment,
    component: () => import(/* webpackChunkName: "OrderStartShipment"*/  '@components/Order/StartShipment')
  },
  {
    path: routes.order.orderInShipment,
    name: routes.order.orderInShipment,
    component: () => import(/* webpackChunkName: "OrderInShipment"*/  '@components/Order/InShipment')
  },
  {
    path: routes.order.orderWaitShipment,
    name: routes.order.orderWaitShipment,
    component: () => import(/* webpackChunkName: "OrderWaitShipment"*/  '@components/Order/WaitShipment')
  },
  {
    path: routes.order.orderInSeaPort,
    name: routes.order.orderInSeaPort,
    component: () => import(/* webpackChunkName: "OrderInSeaPort"*/  '@components/Order/InSeaPort')
  },
  {
    path: routes.order.orderFinalize,
    name: routes.order.orderFinalize,
    component: () => import(/* webpackChunkName: "Finalize"*/  '@components/Order/Finalize')
  },
]
