

<template>
  <app-tooltip :disabled="tooltipDisabled" :text="getTooltip">
    <v-btn v-bind="$attrs" v-on="$listeners" :class="getClasses" :min-width="minWidth" fab small>
      <template v-if="update"><v-icon color="success">mdi-pencil</v-icon></template>
      <template v-if="show"><v-icon color="primary">mdi-eye</v-icon></template>
      <template v-if="destroy"><v-icon color="error">mdi-delete</v-icon></template>
      <template v-if="upload"><v-icon color="primary">mdi-insert_drive_file</v-icon></template>
      <template v-if="driver"><v-icon color="primary">mdi-arrow-up-bold-circle-outline</v-icon></template>
      <template v-if="enter"><v-icon color="primary">mdi-arrow-down-bold-circle-outline</v-icon></template>
      <template v-if="exit"><v-icon color="primary">mdi-location-exit</v-icon></template>
      <template v-if="exit2"><v-icon color="success">mdi-location-exit</v-icon></template>
      <template v-if="exit3"><v-icon color="error">mdi-location-exit</v-icon></template>
      <template v-if="inventory"><v-icon color="success">mdi-package-variant-closed</v-icon></template>
      <template v-if="inventory2"><v-icon color="error">mdi-package-variant-closed</v-icon></template>
      <template v-if="verify"><v-icon color="primary">mdi-bank-transfer</v-icon></template>
      <template v-if="reject"><v-icon color="error">mdi-account-cancel</v-icon></template>
      <template v-if="invalid_photo"><v-icon color="error">mdi-file-alert</v-icon></template>
      <template v-if="convert"><v-icon color="primary">mdi-account-convert</v-icon></template>
      <template v-if="refund"><v-icon color="error">mdi-location-exit</v-icon></template>
      <template v-if="change"><v-icon color="error">mdi-tooltip-edit</v-icon></template>
      <template v-if="tofinalize"><v-icon color="error">mdi-location-exit</v-icon></template>
      <slot></slot>
    </v-btn>
  </app-tooltip>
</template>

<script>
export default {
  name: 'Btn',
  props: {
    minWidth: {
      type: [String, Number],
      default: () => undefined
    },
    update: {
      type: Boolean,
      default: () => false
    },
    updateaddtext: {
      type: String,
      default: () => ''
    },
    show: {
      type: Boolean,
      default: () => false
    },
    showaddtext: {
      type: String,
      default: () => ''
    },
    destroy: {
      type: Boolean,
      default: () => false
    },
    destroyaddtext: {
      type: String,
      default: () => ''
    },
    upload: {
      type: Boolean,
      default: () => false
    },
    uploadaddtext: {
      type: String,
      default: () => ''
    },
    driver: {
      type: Boolean,
      default: () => false
    },
    driveraddtext: {
      type: String,
      default: () => ''
    },
    enter: {
      type: Boolean,
      default: () => false
    },
    enteraddtext: {
      type: String,
      default: () => ''
    },
    exit: {
      type: Boolean,
      default: () => false
    },
    exitaddtext: {
      type: String,
      default: () => ''
    },
    exit2: {
      type: Boolean,
      default: () => false
    },
    exit2addtext: {
      type: String,
      default: () => ''
    },
    exit3: {
      type: Boolean,
      default: () => false
    },
    exit3addtext: {
      type: String,
      default: () => ''
    },
    inventory: {
      type: Boolean,
      default: () => false
    },
    inventoryaddtext: {
      type: String,
      default: () => ''
    },
    verify: {
      type: Boolean,
      default: () => false
    },
    verifyaddtext: {
      type: String,
      default: () => ''
    },
    reject: {
      type: Boolean,
      default: () => false
    },
    rejectaddtext: {
      type: String,
      default: () => ''
    },
    invalid_photo: {
      type: Boolean,
      default: () => false
    },
    invalid_photoaddtext: {
      type: String,
      default: () => ''
    },
    convert: {
      type: Boolean,
      default: () => false
    },
    convertaddtext: {
      type: String,
      default: () => ''
    },
    refund: {
      type: Boolean,
      default: () => false
    },
    refundaddtext: {
      type: String,
      default: () => ''
    },
    change: {
      type: Boolean,
      default: () => false
    },
    changeaddtext: {
      type: String,
      default: () => ''
    },
    inventory2: {
      type: Boolean,
      default: () => false
    },
    inventory2addtext: {
      type: String,
      default: () => ''
    },
    tofinalize: {
      type: Boolean,
      default: () => false
    },
    tofinalizeaddtext: {
      type: String,
      default: () => ''
    },
    tooltip: {
      default: () => undefined
    }
  },
  computed: {
    getClasses: () => [
      'app-dt-btn',
      'my-2',
      'md-3',
      'sm-12'
    ].join(' '),
    getMinWidth() {
      return this.$attrs.icon === undefined ? this.minWidth : undefined;
    },
    getTooltip() {
      if(this.tooltip) return this.parseAttribute(this.tooltip);
      if(this.update) return this.$t('update')+this.updateaddtext;
      if(this.show) return this.$t('show')+this.showaddtext;
      if(this.driver) return this.parseAttribute('assign_driver')+this.driveraddtext;
      if(this.enter) return this.parseAttribute('download')+' '+this.enteraddtext;
      if(this.exit) return this.parseAttribute('exit_car')+this.exitaddtext;
      if(this.exit2) return this.parseAttribute('exit_car2')+this.exit2addtext;
      if(this.exit3) return this.parseAttribute('exit_car3')+this.exit3addtext;
      if(this.inventory) return this.parseAttribute('inventory_enter')+this.inventoryaddtext;
      if(this.inventory) return this.parseAttribute('inventory_enter')+this.inventoryaddtext;
      if(this.verify) return this.parseAttribute('verify')+this.verifyaddtext;
      if(this.reject) return this.parseAttribute('reject')+this.rejectaddtext;
      if(this.invalid_photo) return this.parseAttribute('invalid_photo')+this.invalid_photoaddtext;
      if(this.destroy) return this.$t('destroy')+this.destroyaddtext;
      if(this.upload) return this.$t('upload')+this.uploadaddtext;
      if(this.convert) return this.$t('convert')+this.convertaddtext;
      if(this.refund) return this.$t('refund')+this.refundaddtext;
      if(this.change) return this.$t('change')+this.changeaddtext;
      if(this.tofinalize) return this.$t('tofinalize')+this.tofinalizeaddtext;
      return null;
    },
    tooltipDisabled() {
      return this.getTooltip === null || this.getTooltip === undefined;
    }
  }
};
</script>
