/*
  SarhSoft Cross-PlatForm Engine Project
  Languages: PHP7.4, JavaScript, Node, Java.
  BackEnd: Laravel8.
  FrontEnd: Vue2.
  Project Type: (Laravel,Vue Mixin) Java Cross Frame.
  Written By: M.Farhat,
  Email: m.farhat@sarhsoft.com.
  Copyright © 2022 SarhSoft All rights reserved.
*/
export default {
    code:     'الكود',
    co_code:     'الكود',
    code_value:     'قيمة الخصم',
    counter:  'عدد الاستخدامات',
    exp_date: 'تاريخ الانتهاء',
};
