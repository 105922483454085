import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
let Font = VueQuillEditor.Quill.import('formats/font');
Font.whitelist = ['times-new-roman', 'arial', 'Sans', 'Kufi'];
VueQuillEditor.Quill.register(Font, true);

let Options = {
    modules: {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'font': ['', 'times-new-roman', 'arial', 'Kufi'] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['clean'],
        ],
        syntax: {
            highlight: text => hljs.highlightAuto(text).value
        }
    }
}
Vue.use(VueQuillEditor, Options)
