/*
  SarhSoft Cross-PlatForm Engine Project
  Languages: PHP7.4, JavaScript, Node, Java.
  BackEnd: Laravel8.
  FrontEnd: Vue2.
  Project Type: (Laravel,Vue Mixin) Java Cross Frame.
  Written By: M.Farhat,
  Email: m.farhat@sarhsoft.com.
  Copyright © 2022 SarhSoft All rights reserved.
*/
export default {
      mobile_number: 'mobile_number',
    verification_code: 'verification_code',
    order_id: 'order_id',
    user_id: 'user_id',
    verification_date: 'verification_date',
    is_verified: 'is_verified',

};
