

import {Tools} from '@helpers/tools';
import {extend} from 'vee-validate/dist/vee-validate.full.esm';

extend('int', v => Tools.isNumeric(Tools.fromArabicNumber(v)));

extend('mobile', v => Tools.isKsaMobile(v));
extend('chassie', v => Tools.isChassie(v));
extend('fullname', v => Tools.isFullName(v));

export default extend;
