/*
  SarhSoft Cross-PlatForm Engine Project
  Languages: PHP7.4, JavaScript, Node, Java.
  BackEnd: Laravel8.
  FrontEnd: Vue2.
  Project Type: (Laravel,Vue Mixin) Java Cross Frame.
  Written By: M.Farhat,
  Email: m.farhat@sarhsoft.com.
  Copyright © 2022 SarhSoft All rights reserved.
*/
export default {
  product_id: 'المنتج',
  verfied_user_identity: 'هويات المستخدم المفعلة',
  country_id: 'الدولة',
  city_id: 'المدينة',
  city_bassami: 'فرع البسامي',
  nationality_id: 'الجنسية',
  identity_status: 'حالة الهوية',
  identity_type: 'نوع الهوية',
  identity_type1: 'هوية وطنية',
  identity_type2: 'هوية مقيم',
  user_type: 'نوع الحساب',
  user_type0: 'غير محدد',
  user_type1: 'فرد ( مستخدم )',
  user_type2: 'تاجر فردي',
  user_type3: 'تاجر ( بسجل تجاري )',
  user_status: 'حالة الحساب',
  user_status1: 'جديد/قيد التفعيل',
  user_status2: 'مفعل',
  user_status3: 'موقوف',
  order_status: 'حالة الطلب',
  totals: 'إجمالي الطلب',
  recipient_name: 'اسم المستلم بالبسامي',
  recipient_mobile: 'رقم جوال المستلم بالبسامي',
  temp_plate: 'لوحات ( مؤقته) جمركية',
  fasah_auth: 'تفويض منصة فسح',
  refund_name: 'اسنم المستفيد',
  refund_iban: 'رقم الأيبان/IBan',
  refund_iban2: 'رقم الأيبان/IBan بدون SA',
  refund_bank: 'اسم البنك',
  contract_value: 'قيمة العقد',
  order_status0: 'جديد / بانتظار تأكيد شروط العقد',
  order_status1: 'جديد / بانتظار دفع الدفعة المقدمة',
  order_status2: 'فعال / بانتظار اكمال معلومات الشحن',
  order_status3: 'فعال / بانتظار اكمال قيمة العقد',
  order_status4: 'فعال / بانتظار الشحن',
  order_status5: 'مغلق / مكتمل التنفيذ',
  order_status6: 'ملغي / عدم دفع الدفعة المقدمة',
  order_status7: 'ملغي / عدم دفع قيمة العقد',
  order_status8: 'فعال / قيد الشحن',
  order_status9: 'فعال / وصلت الميناء',
  order_status10:  'فعال / استلمها الناقل',
  order_status11:  'فعال / طلب مرتحع',
  order_status12:  'مغلق / طلب مرتحع',
  order_status13:  'فعال / بانتظار تحويل المرتحع',
  user_identity_id:  'هوية المستخدم',
  order_id: 'رقم الطلب',
};
