/*
  SarhSoft Cross-PlatForm Engine Project
  Languages: PHP7.4, JavaScript, Node, Java.
  BackEnd: Laravel8.
  FrontEnd: Vue2.
  Project Type: (Laravel,Vue Mixin) Java Cross Frame.
  Written By: M.Farhat,
  Email: m.farhat@sarhsoft.com.
  Copyright © 2022 SarhSoft All rights reserved.
*/
export default {
    action_type: 'نوع الإجراء',
    msgtext:     'نص الرسالة',
    msgtext_ar:     'نص الرسالة العربي',
    msgtext_en:     'نص الرسالة الانجليزي',
    code:     'كود الإستدعاء',
    action_type1: '4Mobile',
    action_type2: '4Email',
};
