/*
  SarhSoft Cross-PlatForm Engine Project
  Languages: PHP7.4, JavaScript, Node, Java.
  BackEnd: Laravel8.
  FrontEnd: Vue2.
  Project Type: (Laravel,Vue Mixin) Java Cross Frame.
  Written By: M.Farhat,
  Email: m.farhat@sarhsoft.com.
  Copyright © 2022 SarhSoft All rights reserved.
*/
export default{
    order: {
        index: 'order-index',
        orderApprovePayment: 'order-approve-payment-index',
        orderStartShipment: 'order-start-shipment-index',
        orderInShipment: 'order-in-shipment-index',
        orderWaitShipment: 'order-wait-shipment-index',
        orderInSeaPort: 'order-in-seaport-index',
        orderFinalize: 'order-finalize-index',
    }
}
