export default {
  q: '?',
  sar: 'ريال',
  false: 'Disabled',
  true: 'Enabled',
  progress: 'Progress ...',
  id: '#',
  ar: 'العربية',
  en: 'English',
  printPDF: 'Print/PDF',
  login: 'Login',
  home: 'Home',
  done: 'Done',
  color: 'Color',
  comp_color: 'Component Color',
  logout: 'Logout',
  cancel: 'Cancel',
  yes: 'Yes',
  no: 'No',
  enabled: 'Enabled',
  disabled: 'Disabled',
  save: 'Save',
  change: 'Change',
  options: 'options',
  create: 'Create',
  store: 'Store',
  update: 'Update',
  upload: 'Upload',
  convert: 'Reverse Product Category',
  destroy: 'Delete',
  delete: 'Delete',
  show: 'Show',
  view: 'View',
  category_type: 'Category Type',
  category_type0: 'Undefined',
  category_type1: 'Main Category',
  category_type2: 'Sub Category',
  lubricant_category_parent_id: 'Sub Of',
  control: 'control',
  none: 'None',
  na: 'Na',
  export: 'Export',
  close: 'Close',
  edit: 'Edit',
  add: 'Add',
  register: 'Register',
  print: 'Print',
  today: 'Today',
  day: 'Day',
  date: 'Date',
  now: 'Now',
  am: 'AM',
  pm: 'PM',
  error: 'Error !!',
  search: 'Search',
  exportPdf: 'export to PDF',
  exportExcel: 'export to Excel',
  ImportExcel: 'Import from Excel',
  refreshTable: 'Refresh Page',
  expansionPanelHeaderOptions: 'Control',
  close_window: 'Close Window',
  year: 'Year',
  showDetails: 'Show Details',
  Details: 'Details ',
  information: 'Information ',
  setting: 'Setting',
  month: 'Month',
  non_defined: 'Un-Defined',
  undefined: 'Un-Defined',
  greg_letter: ' AD',
  hijri_letter: ' AH',
  import: 'Import',
  file_desc: 'Attachment Description',
  car_km: 'Pedometer',
  show_first: 'Show at first',
  tofinalize: 'Convert to Close Order',
};
