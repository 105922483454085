/*
  SarhSoft Cross-PlatForm Engine Project
  Languages: PHP7.4, JavaScript, Node, Java.
  BackEnd: Laravel8.
  FrontEnd: Vue2.
  Project Type: (Laravel,Vue Mixin) Java Cross Frame.
  Written By: M.Farhat,
  Email: m.farhat@sarhsoft.com.
  Copyright © 2022 SarhSoft All rights reserved.
*/
export default {
  mobile_number:     'رقم الجوال',
  verification_code: 'كود التفعيل',
  verification_date: 'تاريخ التفعيل',
  is_verified:       'مفعل',
  is_verified2:       'مفعل',
};
