/*
  SarhSoft Cross-PlatForm Engine Project
  Languages: PHP7.4, JavaScript, Node, Java.
  BackEnd: Laravel8.
  FrontEnd: Vue2.
  Project Type: (Laravel,Vue Mixin) Java Cross Frame.
  Written By: M.Farhat,
  Email: m.farhat@sarhsoft.com.
  Copyright © 2022 SarhSoft All rights reserved.
*/
export default {
    brand_id: 'الماركة',
    lubricant_application_id: 'استخدامات المنتج',
    lubricant_fuel_type_id: 'أنواع الوقود',
    lubricant_category_id: 'قسم الزيت',
    price: 'السعر شامل الضريبة',
    contain: 'يحتوي على',
    contain1: 'شد',
    stamp_status: 'حالة الختم',
    stamp_status0: 'الختم معطل',
    stamp_status1: 'الختم مفعل',
    stamp_color: 'لون الختم',
    stamp_color0: 'لون 0',
    stamp_color1: 'لون 1',
    stamp_color2: 'لون 2',
    stamp_color3: 'لون 3',
    stamp_color4: 'لون 4',
    stamp_color5: 'لون 5',
    stamp_color6: 'لون 6',
    stamp_color7: 'لون 7',
    stamp_location: 'مكان الختم',
    stamp_location0: 'أعلى - يمين',
    stamp_location1: 'أعلى - يسار',
    stamp_location2: 'أسفل - يمين',
    stamp_location3: 'أسفل - يسار',
    stamp_string_ar: 'كلمات الختم عربي',
    stamp_string_en: 'كلمات الختم انجليزي',
    sds_uploaded: 'ملف SDS',
    tds_uploaded: 'ملف TDS',
    lubricant_photo_uploaded: 'ملف الصورة',
    LubricantsType1: 'صورة',
    LubricantsType2: 'TDS',
    LubricantsType3: 'SDS',
    LubricantsType4: 'Flag',
    product_category: 'تصنيف المنتج',
    product_category0: 'منتج عام',
    product_category1: 'منتج خاص',
};
