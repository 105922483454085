/*
  SarhSoft Cross-PlatForm Engine Project
  Languages: PHP7.4, JavaScript, Node, Java.
  BackEnd: Laravel8.
  FrontEnd: Vue2.
  Project Type: (Laravel,Vue Mixin) Java Cross Frame.
  Written By: M.Farhat,
  Email: m.farhat@sarhsoft.com.
  Copyright © 2022 SarhSoft All rights reserved.
*/
import axios from 'axios';
const prefix = 'Resources';
const Resource = a => `${prefix}/${a}`;
export default {
  fuelTypes(params = {}, config = {}) {
    const url = Resource('FuelTypes');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  }
};
