export default {
  q: '؟',
  sar: 'SAR',
  false: 'معطل',
  true: 'مفعل',
  progress: 'التقدم...',
  id: '#',
  ar: 'العربية',
  en: 'English',
  printPDF: 'طباعة/PDF',
  printContractPDF: 'طباعة العقد/PDF',
  printBillPDF: 'طباعة الفاتورة/PDF',
  confirm_letter: ' خطاب السحب بعد التوقيع',
  login: 'تسجيل الدخول',
  home: 'الرئيسية',
  done: 'موافق',
  approve: 'تعميد',
  verify: 'تفعيل',
  reject: 'رفض',
  invalid_photo: 'الصورة غير مطابقة',
  logout: 'تسجيل الخروج',
  cancel: 'إلغاء',
  dates: 'التواريخ',
  yes: 'نعم',
  no: 'لا',
  enabled: 'مفعل',
  disabled: 'معطل',
  category_type: 'نوع القسم',
  category_type0: 'غير محدد',
  category_type1: 'قسم رئيسي',
  category_type2: 'قسم فرعي',
  lubricant_category_parent_id: 'فرعي من',
  save: 'حفظ',
  color: 'اللون',
  comp_color: 'لون المربع',
  change: 'تعديل',
  options: 'خيارات',
  create: 'إضافة',
  store: 'إضافة',
  update: 'تعديل',
  upload: 'رفع',
  destroy: 'حذف',
  convert: 'عكس تصنيف المنتج',
  refund: 'مرتجع طلب',
  thestatus: ' الحالة',
  tofinalize: 'تحويل لطلب مكتمل',
  delete: 'حذف',
  show: 'عرض',
  view: 'عرض',
  control: 'تحكم',
  none: 'لا يوجد',
  na: 'بدون',
  export: 'تصدير',
  close: 'إغلاق',
  edit: 'تعديل',
  add: 'إضافة',
  register: 'تسجيل',
  print: 'طباعة',
  today: 'اليوم',
  day: 'اليوم',
  date: 'التاريخ',
  now: 'الآن',
  am: 'ص',
  pm: 'م',
  error: 'خطأ !!',
  search: 'بحث',
  exportPdf: 'تصدير البيانات PDF',
  add2salestring: 'تعديل توقيت بدء البيع',
  approvepaymentstring: 'تم استلام التحويل',
  convertmultistring: 'تعيين المنتجات بتصنيف عام',
  startshipmentstring: 'بدءالشحن',
  inshipmentstring: 'قيد الشحن',
  backinshipmentstring: 'قيد الشحن',
  arrivestring: 'وصلت الميناء',
  waitshipmenttring: 'إغلاق وانهاء',
  seaportstring: 'سلمت للناقل',
  Paymentstring: 'تم السداد',
  exportExcel: 'تصدير البيانات Excel',
  other: 'أخرى',
  ImportExcel: 'استيراد البيانات من Excel',
  refreshTable: 'تحديث الصفحة',
  expansionPanelHeaderOptions: 'الأدوات',
  close_window: 'اغلاق النافذة',
  year: 'سنة',
  showDetails: 'عرض التفاصيل',
  Details: 'تفاصيل ',
  from: 'من',
  to: 'إلى',
  information: 'معلومات ',
  the_information: 'المعلومات ',
  company_dashboard_screen: 'شاشة متابعة المركبات',
  refreshData: 'تحديث البيانات',
  cars: 'المركبات',
  car: ' مركبة',
  car_order: 'بيانات الطلب',
  show_first: 'عرض أول القائمة',
  setting: 'الضبط',
  month: 'شهر',
  non_defined: 'غير محدد',
  undefined: 'غير محدد',
  greg_letter: ' م',
  hijri_letter: ' هـ',
  import: 'استيراد',
  qty: "الكمية",
  price: "السعر",
  contain: "يحتوي على",
  contain1: 'شد',
  total: "الاجمالي",
  sr: " ريال",
  without: "بدون",
  rest: "متبقي",
  note: "ملاحظه: ",
  created_at: 'تاريخ الإضافة',
  updated_at: 'تاريخ التعديل',
  deleted_at: 'تاريخ الحذف',
  created_by: 'أضيف بواسطة',
  updated_by: 'التعديل بواسطة',
  deleted_by: 'حذف بواسطة',
  verified_by: 'تم التفعيل من',
  file_desc: 'وصف المرفق',
  all_notifications: 'عرض كل التنبيهات',
  car_km: 'عداد الكيلو مترات',
  orders_count: 'الطلبات الفعالة',
};
