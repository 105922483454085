<template>
<!--  <v-menu v-model="menuShow1" :close-on-click="false" :close-on-content-click="false" :left="!AppRtl" :right="AppRtl" transition="scale-transition">-->
<!--    <template v-slot:activator="{ on, attrs }">-->
<!--      <app-row dense class="pa-0 ma-0 justify-start" @click="Showmenu(item)">-->
<!--        <v-btn-->
<!--            light small dense class="pa-0 px-2 ma-0 mt-1 mhf-btn"-->
<!--            style="width: 100%" color="error">-->
<!--          {{parseAttribute('missing_documents')}}-->
<!--        </v-btn>-->
<!--      </app-row>-->
<!--    </template>-->
<!--    <v-list :min-width="minWidth" :width="width" two-line>-->
<!--      <v-hover v-slot="{hover}">-->
<!--        <v-list-item v-if="!menuitem.car_uploadid&&(item.status!==7&&item.status!==8)" :class="{ 'primary whiteOnHover elevation-12': hover}" style="min-height: 40px;">-->
<!--          <v-list-item-content style="color: inherit" dense class="pa-0 ma-0">-->
<!--            <v-list-item-subtitle style="color: inherit" dense class="text-center">-->
<!--              {{parseAttribute('carid') }}-->
<!--            </v-list-item-subtitle>-->
<!--          </v-list-item-content>-->
<!--        </v-list-item>-->
<!--      </v-hover>-->
<!--      <v-hover v-slot="{hover}">-->
<!--        <v-list-item v-if="!item.car_leftletter&&(item.status!==7&&item.status!==8)" :class="{ 'primary whiteOnHover elevation-12': hover}" style="min-height: 40px;">-->
<!--          <v-list-item-content style="color: inherit" dense class="pa-0 ma-0">-->
<!--            <v-list-item-subtitle style="color: inherit" dense class="text-center">-->
<!--              {{parseAttribute('leftletter')}}-->
<!--            </v-list-item-subtitle>-->
<!--          </v-list-item-content>-->
<!--        </v-list-item>-->
<!--      </v-hover>-->
<!--      <v-hover v-slot="{hover}">-->
<!--        <v-list-item v-if="!item.car_carphoto&&(item.status!==7&&item.status!==8)" :class="{ 'primary whiteOnHover elevation-12': hover}" style="min-height: 40px;">-->
<!--          <v-list-item-content style="color: inherit" dense class="pa-0 ma-0">-->
<!--            <v-list-item-subtitle style="color: inherit" dense class="text-center">-->
<!--              {{parseAttribute('carphoto')}}-->
<!--            </v-list-item-subtitle>-->
<!--          </v-list-item-content>-->
<!--        </v-list-item>-->
<!--      </v-hover>-->
<!--      <v-hover v-slot="{hover}">-->
<!--        <v-list-item v-if="item.inventory===0&&(item.status===4||item.status===6)" :class="{ 'primary whiteOnHover elevation-12': hover}" style="min-height: 40px;">-->
<!--          <v-list-item-content style="color: inherit" dense class="pa-0 ma-0">-->
<!--            <v-list-item-subtitle style="color: inherit" dense class="text-center">-->
<!--              {{parseAttribute('inventory_enter')}}-->
<!--            </v-list-item-subtitle>-->
<!--          </v-list-item-content>-->
<!--        </v-list-item>-->
<!--      </v-hover>-->
<!--    </v-list>-->
<!--  </v-menu>-->
</template>

<script>
export default {
  name: "HangMenu"
}
</script>

<style scoped>

</style>
