<template>
  <v-list dense>
    <template v-for="(item, i) in items">
      <app-list-item :item="item"></app-list-item>
    </template>
  </v-list>
</template>
<style>
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>

<script>
export default {
  name: "DrawerList",
  props: ['items'],
}
</script>
