
export default {
  name: 'الاسم',
  name_hint: 'حقل الاسم مطلوب',
  name_ar: 'الاسم العربي',
  name_ar_hint: 'حقل الاسم العربي مطلوب',
  name_en: 'الاسم الإنجليزي',
  name_en_hint: 'حقل الاسم الإنجليزي مطلوب',
  login_id: 'اسم المستخدم',
  login_id_hint: 'حقل اسم المستخدم مطلوب',
  password: 'كلمة المرور',
  password_hint: 'حقل كلمة المرور مطلوب',
  password_confirmation: 'تأكيد كلمة المرور',
  password_confirmation_hint: 'حقل تأكيد كلمة المرور مطلوب',
  age: 'العمر',
  age_hint: 'حقل العمر مطلوب',
  birth_date: 'تاريخ الميلاد',
  birth_date_hint: 'حقل تاريخ الميلاد مطلوب',
  bill: 'الفاتورة',
  vatonly: 'الضريبة',
  mobileonly: 'الجوال',
  mobile: 'رقم الجوال',
  mobile_hint: 'رقم الجوال مطلوب',
  coupon_id: 'الكوبون',
  coupon_id_hint: 'الكوبون مطلوب',
  sort_order: 'ترتيب العرض',
  img: 'الصورة',
  active: 'فعال',
  percent: 'النسبة',
  percentage: 'النسبة',
  status: 'الحالة',
  status_label: 'الحالة',
  other: 'غير ذلك',
  false: 'معطل',
  true: 'مفعل',
  notes: 'ملاحظات',
  notes_hint: 'حقل ملاحظات مطلوب',
  type:'النوع',
  type_hint:'حقل النوع مطلوب',
  phone: 'رقم الهاتف',
  phone_hint: 'حقل رقم الهاتف مطلوب',
  email: 'البريد الإلكتروني',
  email_hint: 'حقل البريد الإلكتروني مطلوب',
  fax: 'رقم الفاكس',
  fax_hint: 'حقل رقم الفاكس مطلوب',
  download: 'تحميل',
  download_view: 'تحميل / عرض',
  stamp_status: 'حالة الختم',
  stamp_status0: 'الختم معطل',
  stamp_status1: 'الختم مفعل',
  stamp_color: 'لون الختم',
  stamp_color0: 'لون 0',
  stamp_color1: 'لون 1',
  stamp_color2: 'لون 2',
  stamp_color3: 'لون 3',
  stamp_color4: 'لون 4',
  stamp_color5: 'لون 5',
  stamp_color6: 'لون 6',
  stamp_color7: 'لون 7',
  stamp_location: 'مكان الختم',
  stamp_location0: 'أعلى - يمين',
  stamp_location1: 'أعلى - يسار',
  stamp_location2: 'أسفل - يمين',
  stamp_location3: 'أسفل - يسار',
  stamp_string_ar: 'كلمات الختم عربي',
  stamp_string_en: 'كلمات الختم انجليزي',
}
