/*
  SarhSoft Cross-PlatForm Engine Project
  Languages: PHP7.4, JavaScript, Node, Java.
  BackEnd: Laravel8.
  FrontEnd: Vue2.
  Project Type: (Laravel,Vue Mixin) Java Cross Frame.
  Written By: M.Farhat,
  Email: m.farhat@sarhsoft.com.
  Copyright © 2022 SarhSoft All rights reserved.
*/
export default {
    product_sku: 'SKU',
    manufacturer_id: 'Manufacturer',
    product_model_id: 'Vehicle Model',
    product_year_id: 'Manufacturing Year',
    product_out_color_id: 'Product Exterior Color',
    product_in_color_id: 'Product Interior Color',
    import_type_id: 'Import Type',
    fuel_type_id: 'Fuel Type',
    km: 'KM',
    chassie: 'Chassie',
    price: 'Price',
    contain: 'Contain Qty',
    contain2: 'Contain Qty',
    down_payment: 'Down Payment',
    brokerage_services: 'Brokerage Services',
    body_check: 'Body Check',
    notes: 'Notes',
    product_notes: 'Additional Specifications',
    product_type0: 'None',
    product_type1: 'Vehicle',
    product_type2: 'Spare Part',
    attachments: 'Attachments Product',
    attachment_type: 'Attachment Type',
    attachment_type1: 'Image',
    attachment_type2: 'Video',
    product_category: 'Product Category',
    product_category0: 'Public Product',
    product_category1: 'Private Product',
    color_type: 'Color Type',
    color_type0: 'Global',
    color_type1: 'Outter',
    color_type2: 'Inner',
};
