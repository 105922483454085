/*
  SarhSoft Cross-PlatForm Engine Project
  Languages: PHP7.4, JavaScript, Node, Java.
  BackEnd: Laravel8.
  FrontEnd: Vue2.
  Project Type: (Laravel,Vue Mixin) Java Cross Frame.
  Written By: M.Farhat,
  Email: m.farhat@sarhsoft.com.
  Copyright © 2022 SarhSoft All rights reserved.
*/
export default {
    brand_id: 'Brand',
    lubricant_application_id: 'Lubricant Application',
    lubricant_fuel_type_id: 'Lubricant Fuel Type',
    lubricant_category_id: 'Lubricant Category',
    price: 'Price (VAT incl)',
    contain: 'Contain Qty',
    contain2: 'Contain Qty',
    stamp_status: 'Stamp Status',
    stamp_location: 'Stamp Location',
    stamp_color: 'Stamp Color',
    stamp_string_ar: 'Stamp string AR',
    stamp_string_en: 'Stamp string EN',
    product_category: 'Product Category',
    sds_uploaded: 'SDS Uploaded',
    tds_uploaded: 'TDS Uploaded',
    photo_uploaded: 'Photo Uploaded',
    LubricantsType1: 'Photo',
    LubricantsType2: 'TDS',
    LubricantsType3: 'SDS',
    LubricantsType4: 'Flag',
};
