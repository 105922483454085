/*
  SarhSoft Cross-PlatForm Engine Project
  Languages: PHP7.4, JavaScript, Node, Java.
  BackEnd: Laravel8.
  FrontEnd: Vue2.
  Project Type: (Laravel,Vue Mixin) Java Cross Frame.
  Written By: M.Farhat,
  Email: m.farhat@sarhsoft.com.
  Copyright © 2022 SarhSoft All rights reserved.
*/
export default {
  transaction_type:      'نوع العملية',
  vendor_transaction_id: 'مصدر العملية',
  vendor_response:       'رد المصدر',
  transaction_date:      'تاريخ العملية',
  is_succeeded:          'عملية ناجحة',
};
